import "./public-path"
import { createApp } from "vue"
import App from "./App.vue"
// unocss
import "uno.css"
import "normalize.css/normalize.css"
// 全局样式
import "./styles/index.less"
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'
import { setToastDefaultOptions } from "vant"


import router from "./router"

setToastDefaultOptions({ teleport: "#hs-test" })
const app = createApp(App)
app.use(router)
app.mount("#app")